<template>
  <div class="level-right">
    <div class="help-block">
      <button class="button is-text view-manual" @click="$store.commit('setModalActive', { type: 'organizeSkinner', isActive: true })">
        Xử lý khách chờ gội
        <!--<a class="manual-link" target="_blank" :style="{'color': 'black' }">Xử lý khách chờ gội</a>-->
      </button>

      <button class="button is-text view-manual">
        <a class="manual-link" target="_blank" :style="{'color': 'black'}" href="https://docs.google.com/document/d/1XPD8QL5RHNXfShgDisRjLfDZ0YSYpN_ihDiyx0oOV-Y/view">Hướng dẫn sử dụng chức năng</a>
      </button>
      
      
      <div class="buttons">
        <button class="button is-text is-small">
          <b-icon icon="message-bulleted" :style="{color: '#ff3860'}"/> 
          <span class="category-name">Ghi chú</span>
        </button>
        <button class="button is-text is-small">
          <span class="icon guess-book is-small"></span>
          <span class="category-name">KH book</span>
        </button>
        <button class="button is-text is-small">
          <span class="icon billed is-small"></span>
          <span class="category-name">Đã in bill</span>
        </button>
        <button class="button is-text is-small">
          <span class="icon">
            <b-icon icon="checkbox-marked-outline"></b-icon>
          </span>
          <span class="category-name">KH đang chờ</span>
        </button>
        <button class="button is-text is-small">
          <span class="icon salon-book is-small"></span>
          <span class="category-name">KH book tại salon</span>
        </button>
        <button class="button is-text is-small">
          <span class="icon is-special">
            <b-icon icon="star"></b-icon>
          </span>
          <span class="category-name">KH đặc biệt</span>
        </button>
        <button class="button is-text is-small">
          <span class="icon is-angry">
            <b-icon icon="alert"></b-icon>
          </span>
          <span class="category-name">KH chờ lâu</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryList"
};
</script>

<style lang="scss" scoped>
.help-block {
  margin-right: 1rem;
  .is-text {
    text-decoration: none;
  }
  flex: 1;
  flex-direction: row;
  display: flex;
}

.view-manual {
  margin-right: 20px;
  background-color: #f5f5f5;
  border-radius: 3px;
}

.view-manual:hover {
  background-color: #fcd344;
  
  .manual-link {
    color: white;
  }
}

.buttons .button {
  margin-right: 0px;
}

.buttons .button:not(:last-child):not(.is-fullwidth) {
  margin-right: 0px;
}

.category-name {
  font-size: 11px;
}
</style>
